@use 'base/variables' as v;
@use 'base/mixins' as m;


/* ? SECCIÓN VÍDEO */
/* ?___________________________________________________________________________________________________________________________________________ */

.secVideo {
    position: relative;
    width: 100%;
    min-height: 50vh;

    @include m.mixFlex(flex,flex-start,center,0,0);

    .videoContent {
        position: absolute;
        width: 100%;
        z-index: 10;
        @include m.mixFlex(flex,center,center,0,column);

        .play {
            cursor: pointer;
            width: 80px;
            height: 80px;
            display: block; 
            transition: .5s;
            border-radius: 50%;
        
            -webkit-animation: ripple 2.5s linear 1s infinite;
            animation: ripple 2.5s linear 1s infinite;
        
            img {
                width: 80px;
                transition: width 0.5s;
            }
            
            &:hover {
                background: v.$color1;
            }            
        }        
    }

    .videoPopup {
        position: fixed;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10000;
        width: 100%;
        height: 100%;
        @include m.mixFlex(flex,center,center,0,0);        
        backdrop-filter: blur(25px);
        visibility: hidden;
        opacity: 0;

        &.active {
            visibility: visible;
            opacity: 1;
        }
        
        video {
            max-width: 1200px;
            outline: none;

            @include m.mixMQ(v.$desk) {
                max-width: 90%;
            };    
        }        

        .close {
            position: absolute;
            top: 30px;  right: 30px;
            cursor: pointer;
            max-width: 32px;
            filter: invert(1);
        }    
        
    }        
}
@use 'base/variables' as v;
@use 'base/mixins' as m;

/* ? FOOTER */ 
/* ?___________________________________________________________________________________________________________________________________________ */

footer {

    @include m.mixFlex(flex,0,0,0,column);

    .footer {
        position: relative;
        width: 100%;
        min-height: 25vh;
        padding: 0;

        @include m.mixMQ(v.$desk) {
            min-height: 30vh;            
        };
        
        .footer_content {
            position: relative;
            z-index: 1;
            color: v.$blanco;
            width: 95%;
            margin: 25px auto;
            @include m.mixFlex(flex,space-between,0,0,0);        

            @include m.mixMQ(v.$tablet) {
                flex-wrap: wrap;
                justify-content: center;
                align-items: flex-start;
            };

            .footer_Box {
                @include m.mixFlex(flex,0,center,0,column);        
                width: 33%;
                padding: .5rem;

                @include m.mixMQ(v.$tablet) {
                    width: 40%;
                };

                h3 {
                    padding: .5rem 0;
                    color: v.$color3;

                    @include m.mixMQ(v.$movil) {
                        font-size: 2.25rem;
                    };
                }
                
                p {
                    font-family: v.$fuente_principal;
                    font-size: 1.5rem;
                    padding: .5rem 0;
                    margin-bottom: 0;
                    color: v.$blanco;

                    @include m.mixMQ(v.$desk) {
                        font-size: 1.3rem;
                    };
                }

                .fas, .fab {
                    font-size: 1.8rem;
                    margin-right: 1rem;
                }
                                
                .menu_footer {

                    width: 50%;
                    
                    @include m.mixMQ(v.$tablet) {
                        width: 90%;
                        padding: 0 2rem;
                    };

                    a {
                        display: block;
                        padding: .5rem 0;
                        font-size: 1.5rem;
                        color: v.$blanco;
                        transition: color 0.3s ease;

                        &:hover {
                            color: v.$color1;
                            text-decoration: underline;
                        }                    
                    }    
                }
                
                // footer_Box
                @include m.mixMQ(v.$movil) {
                    width: 90%;
                    justify-content: center;
                    text-align: center;
                    margin: 10px 0;
                };
            }     
            
            @include m.mixMQ(v.$movil) {
                flex-direction: column;
            };
        }        
    }
    
    .derechos {
        text-align: center;
        font-size: 1.5rem;
        background-color: v.$negro;
        color: v.$blanco;
        padding: 2rem;

        p {
            color: v.$blanco;
            font-size: 1.2rem;
        }
        a {
            display: inline;
            color: v.$color1;
        }
        
        span {
            color: v.$color1;
        }
        
       .legales {
            @include m.mixFlex(flex,center,0,0,0);
            a {                
                margin-top: 1rem;
                margin-right: 2rem;
                color: v.$color3;
            }
        }

        .enlaceBDK {
            color: v.$blanco;
            cursor: pointer;
            text-decoration: underline red;

            &:hover {
                color: #cd001a;
            }
        }
    }
}



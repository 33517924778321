@use 'base/variables' as v;
@use 'base/mixins' as m;

// ! SECCIÓN PRECIOS

.secPrecios {
 
    width: 80%;
    text-align: center;
    margin: 0 auto;

    @include m.mixMQ(v.$desk) {
        width: 95%;
    };

    @include m.mixMQ(v.$movil) {
        padding-bottom: 2rem;
    };

    .conten-precios {

        @include m.mixFlex(flex,center,center,wrap,row);
        margin-top: 3rem;

        .precios_img {
            width: 25%;
            height: 450px;

            @include m.mixMQ(v.$tablet) {
                display: none;
            };
            
            img {
                height: 450px;
                width: 100%;
                object-fit: contain;
            }
        }
    
        .area_precios {

            width: 75%;
            @include m.mixFlex(flex,0,center,wrap,row);

            @include m.mixMQ(v.$tablet) {
                flex-direction: column;
                width: 100%;
            };

            .lista-precios {

                @include m.mixFlex(flex,space-evenly,center,nowrap,column);
                text-align: center;

                @include m.mixMQ(v.$tablet) {
                    flex-direction: row;
                    width: 100%;
                };
    
                @include m.mixMQ(v.$movil414) {
                    flex-direction: column;
                    width: 100%;
                };

                .importe {
                    background: v.$degradado5;
                    color: #fff;
                    border-radius: 10px;
                    padding: 40px 35px;
                    margin: 2rem;

                    @include m.mixMQ(v.$movil) {
                        margin: .5rem;
                        padding: 2rem 3rem;
                        width: 80%;
                    };

                    h4 {
                        color: #fff;
                        font-size: 50px;
                        font-weight: 500;
                        margin-bottom: 20px;
                    }
                    
                    p {
                        font-size: var(--subtitulos);
                        font-weight: 300;
                        color: v.$blanco;
                        text-align: left;
                    }                

                    .promo_noche {
                        padding: 2rem;
                        background-color: v.$blanco;
                        border-radius: 10px;
                        margin-bottom: 2rem;
                        color: v.$negro;
                        font-weight: bold;
                    }
                }            
            }

            .precios-text {
                width: 37%;
                text-align: left;
                margin-left: 4rem;

                @include m.mixMQ(v.$tablet) {
                    width: 90%;
                };
                @include m.mixMQ(v.$movil414) {
                    margin-left: 2rem;
                };


                h4 {
                    font-size: 4rem;
                    font-weight: 500;

                    @include m.mixMQ(v.$movil) {
                        font-size: 2.5rem;
                        margin-top: 2rem;
                    };
    
                }

                p {
                    font-weight: 300;
                    margin: 10px 0;

                    i {
                        color: v.$color6;
                    }
                    
                }

                .promo_noche {
                    font-weight: bold;

                    i {
                        font-size: 2rem;
                    }
                }
                            
            }
        }
        
    }
    
    .promocion {
        @include m.mixFlex(flex,center,center,wrap,row);
        margin-top: 2rem;

        .promo_img {
            width: 20%;

            @include m.mixMQ(v.$movil) {
                width: 35%;
            };
        }

        .promo_content {            
            text-align: left;
            margin-left: 2rem;

            @include m.mixMQ(v.$tablet) {
                padding: 1rem;
                text-align: center;
                margin-top: 2rem;
                margin-left: 0;
            };

            h2 span {
                font-weight: bold;
                color: v.$color3;
            }
        }
    }

}
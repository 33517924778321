@use 'base/variables' as v;
@use 'base/mixins' as m;

.nav {
    height: 65px; 
    width: 100%;
    align-items: center;
    position: relative;
    padding: 0 50px;
    display: grid;
    place-items: center;
    position: fixed;
    z-index: 10000;
    background: v.$degradado5;
    
    @include m.mixMQ(v.$desk) {
        padding: 0 30px;
    };

    .nav_logo {
        height: 100%;
        overflow: hidden;

        img {
            height: 90%;

        }
    }

    .nav_container {
        width: 90%;
        @include m.mixFlex(flex,space-between,center,0,column);   
        
        @include m.mixMQ(v.$desk) {
            justify-content: space-around;
        };

        @include m.mixMQ(v.$tablet) {
            height: 80%;
            justify-content: space-between;
        };

        .nav_logo {
            width: 100%;
            text-align: center;
            padding: 2rem 0;
            
            @include m.mixMQ(v.$tablet) {
                width: 30%;
                position: absolute;
                left: 8rem;
            };
            @include m.mixMQ(v.$movil) {
                width: 45%;
                left: 7rem;
            };

            p {
                font-family: v.$fuente_principal;
                color: v.$blanco;
                font-size: 4rem;
                font-weight: bold;
            }
        }

        .nav_principal {
            @include m.mixFlex(flex,center,center,0,row);
            padding: 0;
            width: 100%;
            margin: 0;

            .nav_item {
                font-family: v.$fuente_sec;
                font-size: 2rem;
                color: v.$blanco;
                list-style: none;
                height: 100%;
                padding: 1rem;
                transition: .3s ease-out;

                @include m.mixMQ(v.$tablet) {
                    width: 100%;

                    @include m.mixFlex(flex,center,center,nowrap,0);
                };

                &:hover {
                    border-top: 1.5px solid v.$color1;
                    border-bottom: 1.5px solid v.$color1;
                }
            }

            .nav_links {
                color: v.$blanco;
                padding: 5px;

                @include m.mixMQ(v.$desk) {
                    font-size: 1.5rem;
                };
            }

            .nav_active {
                background: v.$degradado1;
                border-top: 1.5px solid v.$color1;
                border-bottom: 1.5px solid v.$color1;
                border-radius: 5px;
            }

            @include m.mixMQ(v.$tablet) {
                position: fixed;
                flex-direction: column;
                top: 0px !important;
                left:0px;
                width: 50% !important;
                height: 100vh;
                background: v.$color1;
                
                clip-path: circle(0% at 0% 0%);
                overflow: hidden; 
                transition: clip-path 0.8s ease-in-out;
            };

        }

        .menu2 {
            -webkit-clip-path: circle(150% at 100% 100%);
            clip-path: circle(150% at 100% 100%);

        }        

        .nav_social {
            @include m.mixFlex(flex,center,0,nowrap,0);
            gap: .5em;

            .botMenu {
                display: none;

                @include m.mixMQ(v.$tablet) {
                    display: block;
                    position: fixed;
                    top: 1.2rem;
                    left: 1rem;
                    z-index: 100000;

                };
            }
        }        
    }    
}

@use 'base/variables' as v;
@use 'base/mixins' as m;

/* ? DESTACADOS */
/* ?___________________________________________________________________________________________________________________________________________ */

.secDestacados {

    width: 100%;
    // height: 75vh;
    position: relative;

    @include m.mixMQ(v.$deskXL) {
        // height: 60vh;        
    };

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5970763305322129) 100%), url('../img/Fondo_Destacados.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;

        z-index: -1;

    }


    .destacados { 

        @include m.mixFlex(flex,center,center,wrap,0);
        position: relative;
        z-index: 100;
        width: 90%;
        margin: 5rem auto;

        @include m.mixMQ(v.$deskXL) {
            width: 95%;
        };
        @include m.mixMQ(v.$movil) {
            width: 98%;
        };

        .destacados_card {

            @include m.mixFlex(flex,center,center,0,0);
            position: relative;

            @include m.mixBox(20.5%,350px,false,15px,false,v.$box-shadow);
            margin: 1.5rem;
            overflow: hidden;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            border-left: 1px solid rgba(255, 255, 255, 0.5);
            background: rgba(0,0,0, 0.35);

            @include m.mixMQ(v.$tablet) {
                width: 29%;
                height: 320px;
            };

            @include m.mixMQ(v.$tablet) {
                width: 42%;
                margin: 1rem;
            };
            @include m.mixMQ(v.$movil414) {
                width: 95%;
                height: 200px;
            };

        
            .content {
                padding: 3rem;
                transition: 0.5s;
                text-align: center; 

                i {
                    position: absolute;
                    top: 15px;
                    right: 30px;
                    font-size: 6em;
                    color: rgba(255, 255, 255, 0.7);
                    pointer-events: none;
                    transition: 0.5s;    
                    z-index: -1;

                    @include m.mixMQ(v.$movil) {
                        font-size: 3.5em;
                    };
                }
                
                h3 {
                    z-index: 1000;
                    margin-bottom: 1.5rem;
                    color: v.$color1;
                    text-shadow: v.$box-shadow2;
                    color: v.$color3;
                }

                p {
                    color: v.$blanco;
                }                        
            }
        }    
    }

}
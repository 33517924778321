@use 'base/variables' as v;
@use 'base/mixins' as m;

// ! SECCIÓN AGENDA

.secAgenda {
    width: 90%;
    margin: 0 auto;
    padding-top: 0;

    @include m.mixMQ(v.$movil414) {
        width: 98%;
    }

    .agenda_titulo {
        text-align: center;
        margin: 2rem auto;
    }

    .agenda {
        @include m.mixFlex(flex,space-evenly,space-evenly,wrap,row);

        @include m.mixMQ(v.$movil414) {
            justify-content: center;
        }

        .mes {
            width: 22.5%;
            background: v.$degradado5;
            border-radius: 10px;
            padding: 4rem 3.5rem;
            margin: 1rem;
            color: v.$blanco;
            box-shadow: v.$box-shadow;

            @include m.mixMQ(v.$desk) {
                width: 30%;
            }
            @include m.mixMQ(v.$movil) {
                width: 45%;
                margin: .5rem;
                padding: 2rem;
            }

            h3 {
                font-family: v.$fuente_sec;
                font-size: 2.5rem;
                font-weight: normal;

                @include m.mixMQ(v.$movil) {
                    font-size: 1.8rem;
                }
    
            }

            ul {
                @include m.resetear-lista;

                li {
                    font-size: 1.7rem;
                }
            }
        }
    }

    .footer-agenda {
        margin: 3rem auto 0;
        text-align: center;
        padding: 0 2rem;
    }
}
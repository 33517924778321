
// Tipografia
$fuente_principal: 'Oswal', sans-serif;
$fuente_sec: 'Montserrat', sans-serif;
$titHero: 5rem;
$titSec: 3.6rem; 
$titulos: 3rem;  
$titParrafo: 2.3rem;
$titH5: 2rem; 
$descSec: 1.9rem;    
$descCon: 1.6rem; 
 

// Colores  
$negro: #000000;  
$blanco: #FFFFFF;     
$color1: #c71b23;   
$color2: #f7b525;    
$color3: #0071bc;     
$color4: #38a131;      
$color5: #413e40;     
$color6: #00BB2D;  
$degradado1: linear-gradient(0deg, #21aba5 35%, #21aba5 100%);
$degradado2: linear-gradient(180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
$degradado3: linear-gradient(180deg, rgba(220,220,220,1) 0%, rgba(0,0,0,1) 100%);
$degradado4: linear-gradient(0deg, rgba(3,106,28,1) 12%, rgba(0,187,45,1) 62%);
$degradado5: linear-gradient(180deg, #c71b23, #f7b525); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
$degradado6: linear-gradient(0deg, #c71b23, #f7b525); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

// Tamaños de Media Queries 
$movil360: 360px;  
$movilSM: 375px;  
$movil414: 414px;
$movil: 480px;
$tablet: 768px; 
$desk: 992px;
$deskXL: 1200px;    
$deskXXL: 1445px;
$deskSuper: 1900px;

// Bordes
$box-shadow: .5rem .5rem 1.5rem rgba(0, 0, 0, .3);
$box-shadow2: 2px 5px 10px rgba(0, 0, 0, 0.5);
$box-shadow3: 5px 20px 50px rgba(0, 0, 0, 1); 
$box-shadow4: .5px 2px 3px rgba(255, 255, 255, 0.7);

$borde: 1px solid rgb(208, 208, 208);
$borde2: 1px solid #d1d1d1;  
$borde3: 1px solid #707070;  
$borde4: 1px solid rgba(0, 0, 0, .1); 
 
// Otros 

$sepSeccion: 6rem;
$animationSpeed: 25s;  

// VARIABLES PARA LA ANIMACIÓN DEL MOUSE

$colorBg: #222a30;
$colorOutline: #ffffff;
$colorOutFade: #4e5559;

$widthMouse: 21px;
$heightMouse: 44px; 
$borderMouse: 3px;

$widthMouseSM: 15px;
$heightMouseSM: 38px; 
$borderMouseSM: 2px;

$posMouse: 4px;
$posText: 2px;

$sizeTrackball: 5px; 
$posTrackball: 10px;
$shrinkTrackball: 0.4;

$animDuration: 5s;

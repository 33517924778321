@use 'base/variables' as v;
@use 'base/mixins' as m;

.secDespedida {
    width: 100%;
    position: relative;

    .titulo {
        position: absolute;
        top: 20%;
        left: 55%;
        width: 60rem;        

        @include m.mixMQ(v.$deskXXL) {
            top: 10%;
            left: 52%;
        };

        @include m.mixMQ(v.$deskXL) {
            top: 15%;
            left: 50%;
            width: 55rem;
        };

        @include m.mixMQ(v.$desk) {
            width: 48%;
        };

        @include m.mixMQ(v.$tablet) {
            top: 20%;
        };

        @include m.mixMQ(v.$movil) {
            position: relative;
            left: 0;
            width: 95%;
            margin: 5rem auto 0; 
        };

        h2 {
            font-size: v.$titSec + 2rem;

            @include m.mixMQ(v.$deskXL) {
                font-size: v.$titSec + .8rem;                
            };

            @include m.mixMQ(v.$desk) {
                font-size: v.$titSec
            };
    
            @include m.mixMQ(v.$tablet) {
                font-size: v.$titSec - .8rem;
            };

            @include m.mixMQ(v.$movil) {
                font-size: v.$titSec - 1.5rem;                
            };
        }

        p {

            @include m.mixMQ(v.$tablet) {
                font-size: 1.5rem;
            };

            @include m.mixMQ(v.$movil) {
                font-size: 1.3rem;                
            };
        }

        .despedida_contacto {
            margin-top: 2rem;

            a {
                width: 20rem;
                margin: 1rem;

                @include m.mixMQ(v.$tablet) {
                    width: 15rem;
                };
            }
        }
    }
}

.secDespedidaWeb {
    width: 95%;
    margin: 0 auto;
    // height: 415px;

    @include m.mixFlex(flex,center,0,wrap,0);

    .titulo {
        width: 40%;
        margin: auto;

        @include m.mixMQ(v.$deskXL) {
            width: 50%;
        };
        @include m.mixMQ(v.$desk) {
            width: 55%;            
        };
        @include m.mixMQ(v.$desk) {
            width: 60%;
            padding: 1rem;
        };
        @include m.mixMQ(v.$movil) {
            position: relative;
            top: 0;
            left: 0;
            width: 95%;
            margin: 0 auto; 
        };


        h4 {
            margin-top: 2rem;

            span {
                color: v.$color1;
            }
        }

        p {
            @include m.mixMQ(v.$desk) {
                font-size: 1.6rem;
            };

            @include m.mixMQ(v.$tablet) {
                font-size: 1.4rem;
            };

        }

        .despedida_contacto {
            margin-top: 2rem;

            a {
                width: 20rem;
                margin: 1rem;

                @include m.mixMQ(v.$tablet) {
                    width: 15rem;
                };
            }
        }

    }

    .imgDespWeb {
        width: 40%;
        z-index: 2000;

        @include m.mixMQ(v.$tablet) {
            width: 35%;
            @include m.mixFlex(flex,center,flex-end,0,0);
        };
        @include m.mixMQ(v.$movil) {
            width: 70%;
        };

        img {
            height: 100%;
            // width: 100%;
            object-fit: contain;
        }
    }

}
@use 'variables' as v;
@use 'mixins' as m; 

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&family=Oswald:wght@300;400;600;700&display=swap');

html { 
    font-size: 62.5%; 
    box-sizing: border-box; 
    scroll-padding-top: 0rem; 
    scroll-behavior: smooth;    
}  
 
*,     
*:before,      
*:after {    
    box-sizing: inherit;       
    margin: 0;         
} 
 
body { 
    font-family: v.$fuente_principal;  
    font-size: 16px; 

    &::-webkit-scrollbar {  
        width: 8px;
        height: 8px;  
    }

    &::-webkit-scrollbar-thumb {
        background: v.$negro; 
        border-radius: 5px; 
    } 
}  

.body-noscroll {
    overflow-y: hidden;
    overflow-x: hidden; 
}

p {
    font-size: v.$descCon;      
    color: v.$negro; 
    line-height: 1.20;    
    margin: .25rem 0; 
    font-family: v.$fuente_sec;   
} 

.container {
    width: 90%;
    max-width: 120rem; 
    margin: 0 auto;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4 {
    margin: 0 0 1rem 0;
    font-family: v.$fuente_principal;
}

h1 {
    font-size: v.$titHero;  

    @include m.mixMQ(v.$deskXXL) {
        font-size: v.$titHero - 1.5rem;                    
    };

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titHero - 2rem;                    
    };

    @include m.mixMQ(v.$movil414) {
        font-size: v.$titHero - 2.5rem;
    };

    @include m.mixMQ(v.$movilSM) {
        font-size: v.$titHero - 2.8rem;
    };

}

h2 {
    font-size: v.$titSec;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titSec - 1rem;
    }        

    @include m.mixMQ(v.$movil) {
        font-size: v.$titSec - 1.3rem;        
    };

}

h3 {
    font-size: v.$titulos;
}
h4 {
    font-size: v.$titParrafo;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titParrafo - .3rem;        
    };

}

h5 {
    font-size: v.$titH5;

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$titH5 - .3rem;        
    };

    @include m.mixMQ(v.$movil) {
        font-size: v.$titH5 - .6rem;        
    };
}

img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block; 
}

.iconos {
    @include m.mixIcono(4rem, 1.7rem);
    background: v.$color3;
    color: v.$blanco; 

    transition: transform .3s ease-in;
    
    &:hover {
        transform: rotate(360deg);
    }    
}

.iconos-blanco {
    @extend .iconos;

    background: v.$blanco;
    color: v.$color2;
}

.iconos-verde {
    @extend .iconos;

    background: v.$degradado4;
    color: v.$blanco; 
}

.seccion {
    padding: v.$sepSeccion 0;
}

.seccionGris {
    @extend .seccion;
    background: #C9CCD3;
    background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
    background-blend-mode: lighten;
}

.seccionGrisOscuro {
    @extend .seccion;
    background: v.$color4;
}

// Titulo de cada sección 
.titulo {
    margin: 0 auto;
    text-align: center;
    width: 85%;

    .mockup {
        width: 300px;
        margin: 0 auto 3rem;

        @include m.mixMQ(v.$movil) {
            width: 250px;
        };
    }

    h2 {
        text-transform: uppercase;

        span { 
            font-weight: 900;
            color: v.$color1;
            text-shadow: v.$box-shadow2;
        }
    } 

    p {
        font-size: v.$descSec;
        font-weight: bold;

        @include m.mixMQ(v.$tablet) {
            font-size: v.$descSec - .1rem;
        };
        @include m.mixMQ(v.$movil) {
            font-size: v.$descSec - .3rem;
        };

    }

    small {
        color: v.$color1;
        font-weight: bold;
        margin: 1.5rem 0;
        display: block;
    }

    .linea {
        width: 100px;
        border: 1px solid v.$color4;
        margin: 0 auto;
        animation: parpadeo 1.5s linear .5s infinite; 
    }
}

.tituloWhite {
    @extend .titulo;

    h2, p {
        color: v.$blanco;

        span {
            color: v.$color3;
        }
    }
}

.txtWhite {
    color: v.$blanco;
}

.txtM2 {
    margin-top: 2rem!important;
}

.txtM5 {
    margin-top: 5rem;
}

.bor10 {
    border-radius: 10px;
}

.bor10sup {
    border-radius: 10px 10px 0 0;
}

.boton {
    margin: 1rem;
    display: inline-block;
    font-size: v.$descCon;
    color: v.$blanco;
    border-radius: 50px;
    cursor: pointer;
    padding: .8rem 3rem;
    background: v.$color1;
    transition: all 0.4s ease-in;
    border: 2px solid v.$color1;

    &:hover {
        box-shadow: inset -10em 0 0 0 v.$color4, inset 10em 0 0 0 v.$color4;
        border: 2px solid v.$color1;
    }
    
    &:active {
        transform: scale(0.98);
    }    

    @include m.mixMQ(v.$deskXL) {
        font-size: v.$descCon - 0.2rem;
    };
}

.botonVerde {
    @extend .boton;
    background: v.$color6;
    border: 2px solid v.$color6;

    &:hover {
        color: v.$negro;
        border: 2px solid v.$negro;
    }

}

// Clase del botón que moverse al inicio de la pantalla
.botInicio {
    position: fixed;
    right: 20px; 
    bottom: 20px;
    z-index: 10000;
}

#botWP {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 10000;
}

.bannerPub {
    padding: 0;
    width: 80%;
    object-fit: cover;
    overflow: hidden;
    margin: 0 auto;

}

.tit-enorme {    
    font-size: 100px;

    @include m.mixMQ(v.$desk) {
        font-size: 80px;        
    };

    @include m.mixMQ(v.$tablet) {
        font-size: 60px;
    };
    @include m.mixMQ(v.$movilSM) {
        font-size: 45px;
    };
}

.font-bold {
    font-weight: bold;
}

.text-derecha {
    text-align: right;
}

.text-izq {
    text-align: left;
}

.anim-mouse {
    width: 100%;
    position: absolute;
    bottom: 6rem;
    display: flex;
    justify-content: center;
    padding-right: 3rem;

    .mouse {
        @include m.bgGradient;
        position: relative;
        width: v.$widthMouse;
        height: v.$heightMouse;
        border-radius: 100px;
        background-size: 100% 200%;
        animation: 
        colorSlide v.$animDuration linear infinite,
        nudgeMouse v.$animDuration ease-out infinite;

        @include m.mixMQ(v.$tablet) {
            width: v.$widthMouseSM;
            height: v.$heightMouseSM;                
        };
        
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            margin: auto;
        }
        &:before {
            width: v.$widthMouse - v.$borderMouse;
            height: v.$heightMouse - v.$borderMouse;
            background-color: v.$colorBg;
            border-radius: 100px;

            @include m.mixMQ(v.$tablet) {
                width: v.$widthMouseSM - v.$borderMouseSM;
                height: v.$heightMouseSM - v.$borderMouseSM;                    
            };
        }
        &:after {
            background-color: v.$colorOutline;
            width: v.$sizeTrackball; 
            height: v.$sizeTrackball;
            border-radius: 100%;
            animation: trackBallSlide v.$animDuration linear infinite;
        }
    }
}

.mouse-b2 {
    bottom: 2rem;
}

.filtro-negro { 
    position: absolute;
    width: 100%;
    height: 100%; 
    z-index: -5;
    top: 0;
    left: 0;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        content: "";
        background: linear-gradient(270deg, rgba(0,0,0,.1) 20%, rgba(0,0,0,1) 100%);        

        @include m.mixMQ(v.$tablet) {
            background-image: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 100%);            
        };
    }

    img {
        object-fit: cover;
        height: 100%;
    }
}

.filtro-negro-border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px 10px 0 0;
    }    
}


.filtro-negro-deg2 {
    @extend .filtro-negro;

    &::before {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
    }    
}

.filtro-negro-deg2border {
    @extend .filtro-negro;

    &::before {
        border-radius: 10px;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%);
    }    
}


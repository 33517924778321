@use 'base/variables' as v;
@use 'base/mixins' as m;

.formatoHero { 
    width: 100%; 
    height: 100vh;
    min-height: 57rem;
    position: relative;  
    color: #fff;
    overflow: hidden; 
    @include m.mixFlex(flex,center,center,0,column);

    .fondo_hero {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        z-index: -1; 

        opacity: 0;
        filter: brightness(0%);
        transition: all 1.25s ease-in-out;

        img {
            object-fit: cover;
            height: 100%;
            object-position: 50% 50%;
        }

        .move_right {
            @include m.mixMQ(v.$deskXXL) {
                object-position: 1% 50%;                
            };
            @include m.mixMQ(v.$tablet) {
                object-position: 50% 50%;                
            };
        };

        .move_left {
            @include m.mixMQ(v.$deskXXL) {
                object-position: 100% 50%;                
            };
            @include m.mixMQ(v.$tablet) {
                object-position: 50% 50%;                
            };
        };

    }

    .banner_visible {
        opacity: 1;
        filter: brightness(100%);
    }

}

.clsBefore {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; 
}


.formatoHero_Container {
    position: absolute;
    top: 65px;
    left: 0;
    // border: 5px solid v.$color2;
    // background: v.$color2;
    width: 100%;
    height: 90vh;
    @include m.mixFlex(flex,center,center,0,row);

    @include m.mixMQ(v.$tablet) {
        flex-direction: column;
    };

    .hero_content {
        width: 35%;
        // border: 1px solid v.$color1;

        @include m.mixMQ(v.$tablet) {
            width: 100%;
            text-align: center;
        };

        h1 {
            text-transform: uppercase;    
            margin-bottom: 10px ;
            text-shadow: v.$box-shadow;
            text-align: left;
            font-weight: 900; 
            color: v.$color4;

            @include m.mixMQ(v.$tablet) {
                text-align: center;            
            };
            
        }

        p {
            font-family: v.$fuente_sec;
            color: v.$negro;
            font-size: v.$titParrafo + .3rem;
            margin-top: 0; 

            @include m.mixMQ(v.$deskXL) {
                font-size: v.$titParrafo + .2rem;            
            };
            
            @include m.mixMQ(v.$tablet) {
                font-size: v.$titParrafo - .3rem;            
            };
        }

        .hero-logo {
            height: 450px;
            margin-bottom: 2rem;

            @include m.mixMQ(v.$tablet) {
                margin-top: 2rem;
                height: 180px;
            };            

            @include m.mixMQ(v.$movil) {
                height: 150px;
                width: 350px;
                text-align: center;
                margin: 2rem auto;
            };            

            img {
                height: 100%;
                // width: auto;
                object-fit: contain;
                margin: 0;
                padding: 0;
            }
        }
    }

    .hero_fotos {
        width: 60%;
        height: 75vh;
        overflow: hidden;

        // border: 1px solid v.$color2;
        @include m.mixFlex(flex,center,center,0,row);

        @include m.mixMQ(v.$desk) {
            flex-direction: column-reverse;
        };

        @include m.mixMQ(v.$tablet) {
            flex-direction: row;
            width: 100%;   
            padding: 2rem;
        };

        @include m.mixMQ(v.$movil) {
            flex-direction: column-reverse;
        };
        
        .fotos_minis {
            width: 20%;
            // padding: 2rem;
            overflow: hidden;
            @include m.mixFlex(flex,center,center,nowrap,column);

            @include m.mixMQ(v.$desk) {
                flex-direction: row;
                width: 80%;
                height: 20%;
            };

            @include m.mixMQ(v.$tablet) {
                flex-direction: column;
                width: 20%;
                height: auto;
            };

            @include m.mixMQ(v.$movil) {
                flex-direction: row;
                width: 80%;
                height: 25%;
            };

            .minifoto {
                width: 75%;
                height: 15%;
                margin: .5rem;

                img {
                    object-fit: contain;
                    border-radius: 10px;
                }
            }
        }

        .fotos_grande {
            width: 80%;
            height: 100%;
            padding: 2rem;
            overflow: hidden;

            @include m.mixMQ(v.$desk) {
                width: 100%;
            };
            @include m.mixMQ(v.$tablet) {
                width: 80%;
            };
            @include m.mixMQ(v.$movil) {
                width: 100%;
            };

            .fotos_fondo {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;

                .foto {
                    position: absolute;
                    // top: 0;
                    // left: 0;
                    width: 100%;
                    height: 100%;
                    // z-index: -1; 
            
                    opacity: 0;
                    filter: brightness(0%);
                    transition: all 1.25s ease-in-out;
            
                    img {
                        object-fit: cover;
                        height: 100%;
                        border-radius: 10px;

                        // object-position: 50% 50%;
                    }
    
                }

                .foto_visible {
                    opacity: 1;
                    filter: brightness(100%);
                }
            }            
        }
    
    }


}

.top-dist {
    @include m.mixMQ(v.$deskSuper) {
        top: 55%;        
    };
}

.centra-titulo {
    transform: translateY(-50%);
}



// ! ===========================================================================================================

.wrapper { 
    position: absolute;
    bottom: 0;
    @include m.mixFlex(flex,center,0,0,0);
    @include m.mixBox(100%,false,false,false,v.$degradado6,v.$box-shadow);
    backdrop-filter: blur(50px);

    .dynamic-txts {
        @include m.resetear-lista;
        text-align: center;
        margin-left: 15px;
        line-height: 45px;    
        height: 45px;        
        letter-spacing: 10px;
        overflow: hidden;

        @include m.mixMQ(v.$movil) {
            letter-spacing: 5px;            
        };

        li {
            color: v.$blanco;
            font-size: v.$titParrafo;
            font-weight: 600;
            top: 0;
            position: relative;

            padding: 0 15px;
            animation: slide 18s steps(6) infinite;

            @include m.mixMQ(v.$movil) {
                font-size: v.$titParrafo - 1rem;
            };

            &::after {
                content: "";
                position: absolute;
                left: 0;
                height: 100%;
                width: 100%;
                background: v.$degradado6;
                border-left: 2px solid v.$blanco;
                animation: typing 3s steps(24) infinite;
            }
        }
    }
}
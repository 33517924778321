@use 'base/variables' as v;
@use 'base/mixins' as m;


#sec_contenido {

    .contenido_content {
        
        width: 90%;
        margin: 2rem auto;
        @include m.mixFlex(flex,center,center,wrap,'');

        @include m.mixMQ(v.$deskXL) {
            flex-direction: column;
        };
        @include m.mixMQ(v.$desk) {
            width: 98%;
        };

        .sec_contenido_img {

            width: 30%;

            @include m.mixMQ(v.$movil) {
                width: 50%;
            };

            img {
                object-fit: contain;
                height: 100%;
            }
        }

        .sec_contenido {
            @include m.mixFlex(flex,center,center,nowrap,0);
            padding: 4rem 2rem; 
            margin: 0 auto;
            width: 70%;

            @include m.mixMQ(v.$deskXL) {
                width: 100%
            };
            @include m.mixMQ(v.$movil) {
                padding: 3rem;
            };

            .contenido_lista {

                @include m.mixFlex(flex,flex-start,0,wrap,0);
                margin-left: 2rem;
                width: 100%;

                @include m.mixMQ(v.$tablet) {
                    width: 100%;
                    margin-left: 0;
                };

                .contenido_area {
                    @include m.mixBox(28%,false,false,10px,v.$degradado5,v.$box-shadow);
                    padding: 3.5rem 2rem 1.5rem;
                    margin: 2rem;
                    position: relative;

                    @include m.mixMQ(v.$tablet) {
                        width: 26%;
                    };

                    @include m.mixMQ(v.$movil) {
                        width: 100%;
                    };
                    // @include m.mixMQ(v.$movil360) {
                    //     width: 80%;
                    // };

                    h4 {
                        margin-bottom: 1.5rem;
                        color: v.$blanco;
                    }

                    ul {
                        margin-left: 1rem;

                        @include m.mixMQ(v.$tablet) {
                            padding: 0;
                        };


                        li {
                            font-family: v.$fuente_sec;
                            color: v.$blanco;
                            font-size: v.$descCon;
                            text-align: left;    
                            margin: .5rem 0;

                        }
                    }

                    p {
                        font-family: v.$fuente_sec;
                        color: v.$blanco;
                        font-size: v.$descCon - .14rem;
                        text-align: left;

                        @include m.mixMQ(v.$desk) {
                            font-size: v.$descCon - .35rem;
                        };                    
                    }

                    i {
                        @include m.mixIcono(6rem, 2rem);
                        background-color: v.$color2;
                        color: v.$blanco;

                        position: absolute;
                        top: -3rem;
                        left: 2rem;
                    }
                }
            }
        }
    }
}